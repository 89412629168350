import { AsyncAction, AsyncFulfilledAction } from 'types/redux-thunk-promise';

export interface CentersState {
  byId: {
    [id: string]: Center;
  };
  allIds: string[];
}

export const CENTERS_LIST_FETCH = 'CENTERS_LIST/FETCH';
export const CENTERS_LIST_FETCH_PENDING = 'CENTERS_LIST/FETCH_PENDING';
export const CENTERS_LIST_FETCH_FULFILLED = 'CENTERS_LIST/FETCH_FULFILLED';
export const CENTERS_LIST_FETCH_REJECTED = 'CENTERS_LIST/FETCH_REJECTED';

interface CentersListFetchAction extends AsyncAction<CentersListApi> {
  type: typeof CENTERS_LIST_FETCH;
}

type CentersListFetchFulfilledAction = AsyncFulfilledAction<CentersListFetchAction, typeof CENTERS_LIST_FETCH_FULFILLED>;

export const CENTER_FETCH = 'CENTER/FETCH';
export const CENTER_FETCH_PENDING = 'CENTER/FETCH_PENDING';
export const CENTER_FETCH_FULFILLED = 'CENTER/FETCH_FULFILLED';
export const CENTER_FETCH_REJECTED = 'CENTER/FETCH_REJECTED';

interface CenterFetchAction extends AsyncAction<Center> {
  type: typeof CENTER_FETCH;
}

type CenterFetchFulfilledAction = AsyncFulfilledAction<CenterFetchAction, typeof CENTER_FETCH_FULFILLED>;

export const CENTER_PATCH = 'CENTER/PATCH';
export const CENTER_PATCH_PENDING = 'CENTER/PATCH_PENDING';
export const CENTER_PATCH_FULFILLED = 'CENTER/PATCH_FULFILLED';
export const CENTER_PATCH_REJECTED = 'CENTER/PATCH_REJECTED';

interface CenterPatchAction extends AsyncAction<Center> {
  type: typeof CENTER_PATCH;
}

type CenterPatchFulfilledAction = AsyncFulfilledAction<CenterPatchAction, typeof CENTER_PATCH_FULFILLED>;

export type CentersActionTypes =
  | CentersListFetchAction
  | CentersListFetchFulfilledAction
  | CenterFetchAction
  | CenterFetchFulfilledAction
  | CenterPatchAction
  | CenterPatchFulfilledAction;
